import React from 'react';
import style from './Upload.module.css'

const Upload = ({status, upload, updateFileName}) => {
    return (
        <div className={style.upload}>
            <form encType="multipart/form-data" method="post" className={style.form} onSubmit={upload}>
                <input type="file" name="uploadedFile" multiple="multiple" accept={"text/csv"} onChange={updateFileName}/>
                <button type="submit">Upload</button>
            </form>
            <div className={style.status}>{!status && "The error uploading file. Please try again."}</div>
        </div>
    )
};

export default Upload;