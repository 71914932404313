import './App.css';
import Table from "./components/table/Table";

import {useEffect, useRef, useState} from "react";
import Upload from "./components/upload/Upload";

const BASE_API = process.env.REACT_APP_BASE_API || "http://localhost:4040/api/v1/excel"

function App() {
    const UP_ARROW_IMAGE = "/images/up-arrow.svg"
    const DOWN_ARROW_IMAGE = "/images/down-arrow.svg"
    const APP_NAME = "CellFlex"
    const ASCENDING = 0
    const DESCENDING = 1
    const initialHeaders = useRef([])
    const initialData = useRef([])
    const [headers, setHeaders] = useState(initialHeaders.current)
    const [sortBy, setSortBy] = useState(0)
    const [data, setData] = useState(initialData.current.sort())
    const [sortOrder, setSortOrder] = useState(ASCENDING)
    const [editField, setEditField] = useState(null)
    const [searchEnabled, setSearchEnabled] = useState(false)
    const [searchValue, setSearchValue] = useState("")
    const [uploadedFileName, setUploadedFileName] = useState("")
    const [arrowImage, setArrowImage] = useState(DOWN_ARROW_IMAGE)
    const [showImageId, setShowImageId] = useState(0)
    let recentSortByIndex = sortBy, recentSortByOrder =  sortOrder;

    const sortByIndexWithOrder = (first, second) => {
        const index = recentSortByIndex;
        const order = recentSortByOrder;
        if (typeof first[index] === "number" && typeof second[index] === "number") {
            if (order === ASCENDING) {
                setSortOrder(DESCENDING)
                return second[index] - first[index]
            } else {
                setSortOrder(ASCENDING)
                return first[index] - second[index]
            }
        } else if (typeof first[index] === "string" &&  typeof second[index] === "string") {
            if (order === ASCENDING) {
                setSortOrder(DESCENDING)
                return second[index].localeCompare(first[index])
            } else {
                setSortOrder(ASCENDING)
                return first[index].localeCompare(second[index])
            }
        }else if (typeof first[index] === "number") {
            return -1;
        } else if (typeof second[index] === "number") {
            return 1;
        }
    }

    const sortData = (e) => {
        setSortBy(e.target.cellIndex)
        recentSortByIndex = e.target.cellIndex
        const sortedData = [...initialData.current].sort(sortByIndexWithOrder)
        setData(sortedData)
        setArrowImage(arrowImage === UP_ARROW_IMAGE ? DOWN_ARROW_IMAGE: UP_ARROW_IMAGE)
        setShowImageId(recentSortByIndex)
    }
    const edit = (e) => {
        setEditField({
            row: parseInt(e.target.parentNode.dataset.row, 10),
            column: e.target.cellIndex
        })
    }

    const save = (e) => {
        e.preventDefault()
        data[editField.row][editField.column] = e.target.elements["cellContent"].value
        setEditField(null)
    }

    const updateFileName = (e) => {
        setUploadedFileName(e.target.files[0].name)
    }

    const getData = async (filename="") => {
        let url;
        if (filename){
            url = `${BASE_API}/read/${filename}`
        }
        else{
            url = `${BASE_API}/read`
        }
        try{
            const res = await fetch(url)
            return await res.json()
        }catch (err){
            console.log(`Error fetching initial data. ${err}`)
            return null
        }
    }

    useEffect( () => {
        (async function(){
            const res = await getData()
            if(res !== null){
                setHeaders(res.result.headers)
                setData(res.result.data)
                initialHeaders.current = res.result.headers
                initialData.current = res.result.data
            }
        })()
    },[])



    const toggleSearch = () => {
        setSearchEnabled(prev => !prev)
    }

    const search = (e) => {
        const searchValue = e.target.value
        const column = e.target.dataset.column
        setSearchValue(searchValue)
        const filteredData  = initialData.current.filter(row => row[column].toString().toLowerCase().includes(searchValue))
        setData(filteredData)
    }


    const upload = async (e) => {
        e.preventDefault()
        const res = await fetch(`${BASE_API}/upload`, {
            method: "POST",
            mode: 'cors',
            body: new FormData(e.target)
        })
        const status = await res.json()
        if (status.error === null){
            const res = await getData(uploadedFileName)
            if(res !== null){
                setHeaders(res.result.headers)
                setData(res.result.data)
                initialHeaders.current = res.result.headers
                initialData.current = res.result.data
            }
        }

    }
    return (<div className="App">
            <h1>{APP_NAME}</h1>
            <Upload status={true} upload={upload} updateFileName={updateFileName}/>
            <hr/>
            <div className="btn-group">
                <button type={"button"} onClick={toggleSearch}>{searchEnabled ? "Hide Search": "Show Search"}</button>
            </div>
            <Table
                headers={headers}
                initialData={data}
                sortData={sortData}
                edit={edit}
                editField={editField}
                save={save}
                sortOrder={sortOrder}
                search={search}
                searchEnabled={searchEnabled}
                searchValue={searchValue}
                arrowImage={arrowImage}
                showImageId={showImageId}
            />
        </div>);
}

export default App;
