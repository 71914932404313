import React from 'react';
import styles from './Table.module.css'

function Table({headers, initialData, sortData, edit, editField, save, searchEnabled, search, arrowImage, showImageId}) {
    return (
        <div className={styles.tableContainer}>
            <table className={styles.table}>
                <thead onClick={sortData}>
                <tr>
                    {headers.map((header, column) => <td key={column}>{header}{showImageId === column && <img src={arrowImage} alt="arrow"/>}</td>)}
                </tr>
                </thead>
                <tbody onDoubleClick={edit}>
                <tr>{searchEnabled && headers.map((header, columnidx) => <td key={columnidx}><input data-column={columnidx}
                                                                                                    onChange={search}/>
                </td>)}</tr>
                {initialData.map((row, rowidx) => <tr data-row={rowidx} key={rowidx}>{row.map((cell, columnidx) => {
                    if (editField && editField.row === rowidx && editField.column === columnidx) {
                        cell = (
                            <form onSubmit={save}>
                                <input type="text" defaultValue={cell} name={"cellContent"}/>
                            </form>
                        )
                    }
                    return <td key={columnidx}>{cell}</td>
                })}</tr>)}
                </tbody>
            </table>
        </div>
    );
}

export default Table;
